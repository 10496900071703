<template>
  <v-theme-provider dark>
    <base-section
      id="contact-us"
      class="primary"
      space="40"
    >
      <base-parallax>
			</base-parallax>

    </base-section>


			
		
  </v-theme-provider>
</template>

<script>
import BaseParallax from "@/components/base/parallax"
  export default {
		name: 'SectionParallax',
		components: {
			BaseParallax
		}
	}
	
</script>
